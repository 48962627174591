<template>
  <div class="pageWidth">
    <v-row>
      <v-col cols="12" class="mt-5"></v-col>
      <v-col cols="12" class="">
        <!-- Header -->
        <v-img src="../../assets/images/homePageGradient.png" height="250">
        <p class="text-center latoFont smabMainHeadline primaryThemeColor">SMART BUSINESS 4 ALL</p>
        </v-img>
      </v-col>
      <v-col cols="2">
        <!-- Image of a bulb -->
        <!-- <v-img class="mt-15 ml-10" src="img/EVOLVE.png"  max-height="130" max-width="130" contain></v-img> -->
      </v-col>
      <v-col cols="8" class="">
        <!-- Title -->
        <p class="text-center latoFont smabHeadliners primaryTextColor">Who are we?</p>
        <!-- Description -->
        <p class="text-center smabDescriptionText primaryTextColor">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
          ullamco laboris nisi ut aliquip ex ea commodo consequat. 
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore nim id est laborum.Sedluptatem accusantium doloremque laudantium,totam rem aperiam.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  }
}
</script>

<style scoped>
  @import '../../assets/styles/styleSMAB.css';
</style>