<template>
  <div :class="$vuetify.breakpoint.lgAndDown ? 'pr-3 pl-3' : ''">
    <v-row v-if="$vuetify.breakpoint.smAndUp">
      <!-- ===== About Company ===== -->
      <v-col cols="12" align="center" class="mt-15">
        <v-col cols="12" align="left" xl="8" lg="10" md="12" sm="12" xs="12" :class="$vuetify.breakpoint.smAndDown ? 'mt-0' : 'mt-15'">
          <v-row>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              align-self="center"
              class="pt-0"
              order-xl="first"
              order-lg="first"
              order-md="first"
              order-sm="last"
              order-xs="last"
            >
              <p class="caHeadline">About CA4GF</p>
              <p class="caBody mt-6">
                The project idea came from art schools needs to develop their services and increased interest in topics such as ecology and heritageissues amongst their students. 
                During the centuries-old occupations in Latvia and Lithuania, the heritage, which testifies to the long history of Lithuanian and Latvian statehood, was especially actively destroyed and is steadily disappearing.
              </p>
              <v-chip class="aboutChip mr-6 mt-6">Entrepreneurship</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">Art & Culture</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">Cultural Heritage</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">Design</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">Sustainability</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">Creativity</v-chip>
            </v-col>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              class="pt-0"
              order-xl="last"
              order-lg="last"
              order-md="last"
              order-sm="first"
              order-xs="first"
            >
              <div>
                <v-img src="/img/aboutgreen.png"></v-img>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <!-- THE GOAL -->
      <v-col cols="12" align="center" class="mt-15">
        <v-col cols="12" align="left" xl="8" lg="10" md="12" sm="12" xs="12" class="mt-15">
          <v-row>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              align-self="center"
              class="pt-0"
              order-xl="first"
              order-lg="first"
              order-md="first"
              order-sm="last"
              order-xs="last"
            >
              <div>
                <v-img src="/img/aboutyellow.png"></v-img>
              </div>
            </v-col>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              align-self="center"
              class="pt-0"
              order-xl="last"
              order-lg="last"
              order-md="last"
              order-sm="first"
              order-xs="first"
            >
              <p class="caTitle">The goal</p>
              <p class="caBody mt-6">
                The main aim of the project is to develop entrepreneurship competences of art schools’ students‘ in Baltic-Nordic region countries by developing a entrepreneurship curriculum that focuses on cultural creativity and sustainability.   
                The project also aims to facilitate multiple seminars and workshops, hosted in Lithuania, Latvia and Norway, to develop cooperation and support knowledge exchange between project partners. 
                Short-term objectives are:1. To develop cooperation and knowledge exchange between project partners2. 
                To create a new curriculum for cultural entrepreneurship in Latvia, Lithuania, and Norway3. To spread information about projects ‘results to other Nordic region countries4. 
                To ensure the quality of project results 
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <!-- FOR WHOM -->
      <!-- <v-row class="pl-9 pr-3"> -->
      <!-- <v-col cols="12" align="center" class="mt-15 pa-0 pb-12" style="background-color: #f1f1f1"> -->
      <v-col cols="12" align="center" class="mt-15">
        <v-col cols="12" align="left" xl="8" lg="10" md="12" sm="12" xs="12" class="mt-15">
          <v-row>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              align-self="center"
              class="pt-0"
              order-xl="first"
              order-lg="first"
              order-md="first"
              order-sm="last"
              order-xs="last"
            >
              <p class="caTitle">For whom</p>
              <p class="caBody mt-6">
                The project results are meant to be used in art schools to teach students aged 15-19 years about entrepreneurship in arts and cultural sustainability.  
                Other project activities, such as seminars and workshops, are targeted towards teachers within the art, design and cultural fields, for knowledge exchange, inspiration and building cooperation bonds across borders.
              </p>
            </v-col>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              class="pt-0"
              order-xl="last"
              order-lg="last"
              order-md="last"
              order-sm="first"
              order-xs="first"
            >
              <div>
                <v-img src="/img/aboutblue.png"></v-img>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <!-- </v-row> -->
      <!-- WHY -->
      <v-col cols="12" align="center" class="mt-15 mb-15 pb-15">
        <v-col cols="12" align="left" xl="8" lg="10" md="12" sm="12" xs="12" class="mt-15">
          <v-row>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              align-self="center"
              class="pt-0"
              order-xl="last"
              order-lg="last"
              order-md="last"
              order-sm="first"
              order-xs="first"
            >
              <div>
                <v-img src="/img/aboutred.png"></v-img>
              </div>
            </v-col>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              align-self="center"
              class="pt-0"
              order-xl="last"
              order-lg="last"
              order-md="last"
              order-sm="first"
              order-xs="first"
            >
              <p class="caTitle">Why</p>
              <p class="caBody mt-6">
                Nowadays, art, culture and creativity are defined in the sense that they contribute to the generation and production of knowledge. 
                They are products that are created, need a market, and thrive on clients. Besides, culture and creativity have an aesthetic, social, political, economic and idealistic value. 
                Yet, culture, art and creativity waste away if they do not have a market and if they are not received. 
                To make that reception possible it needs entrepreneurship and an effective cultural and creative business. 
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
    </v-row>

    <!-- =============== PHONE =============== -->

    <v-row v-if="$vuetify.breakpoint.xsOnly">
      <!-- ===== About Company ===== -->
      <v-col cols="12" align="center" class="mt-15">
        <v-col cols="12" align="left" xl="8" lg="10" md="12" sm="12" xs="12" :class="$vuetify.breakpoint.smAndDown ? 'mt-0' : 'mt-15'">
          <v-row>
            <v-col cols="12" class="pa-0">
              <div>
                <v-img src="/img/aboutgreen.png"></v-img>
              </div>
            </v-col>
            <v-col cols="12">
              <p class="caHeadline">About CA4GF</p>
              <p class="caBody mt-6">
                The project idea came from art schools needs to develop their services and increased interest in topics such as ecology and heritageissues amongst their students. 
                During the centuries-old occupations in Latvia and Lithuania, the heritage, which testifies to the long history of Lithuanian and Latvian statehood, was especially actively destroyed and is steadily disappearing.
              </p>
              <v-chip class="aboutChip mr-6 mt-6">Entrepreneurship</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">Art & Culture</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">Cultural Heritage</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">Design</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">Sustainability</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">Creativity</v-chip>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <!-- THE GOAL -->
      <v-col cols="12" align="center" class="mt-5">
        <v-col cols="12" align="left" xl="8" lg="10" md="12" sm="12" xs="12" class="mt-15">
          <v-row>
            <v-col cols="12" align-self="center" class="pa-0">
              <div>
                <v-img src="/img/aboutyellow.png"></v-img>
              </div>
            </v-col>
            <v-col cols="12" align-self="center" class="pt-0">
              <p class="caTitle">The goal</p>
              <p class="caBody mt-6">
                The main aim of the project is to develop entrepreneurship competences of art schools’ students‘ in Baltic-Nordic region countries by developing a entrepreneurship curriculum that focuses on cultural creativity and sustainability.   
                The project also aims to facilitate multiple seminars and workshops, hosted in Lithuania, Latvia and Norway, to develop cooperation and support knowledge exchange between project partners. 
                Short-term objectives are:1. To develop cooperation and knowledge exchange between project partners2. 
                To create a new curriculum for cultural entrepreneurship in Latvia, Lithuania, and Norway3. To spread information about projects ‘results to other Nordic region countries4. 
                To ensure the quality of project results 
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <!-- FOR WHOM -->
      <!-- <v-row class="pl-9 pr-3"> -->
      <!-- <v-col cols="12" align="center" class="mt-15 pa-0 pb-12" style="background-color: #f1f1f1"> -->
      <v-col cols="12" align="center" class="mt-5">
        <v-col cols="12" align="left" xl="8" lg="10" md="12" sm="12" xs="12" class="mt-15">
          <v-row>
            <v-col cols="12" class="pa-0">
              <div>
                <v-img src="/img/aboutblue.png"></v-img>
              </div>
            </v-col>
            <v-col cols="12" align-self="center" class="pt-0">
              <p class="caTitle">For whom</p>
              <p class="caBody mt-6">
                The project results are meant to be used in art schools to teach students aged 15-19 years about entrepreneurship in arts and cultural sustainability.  
                Other project activities, such as seminars and workshops, are targeted towards teachers within the art, design and cultural fields, for knowledge exchange, inspiration and building cooperation bonds across borders.
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <!-- </v-row> -->
      <!-- WHY -->
      <v-col cols="12" align="center" class="mt-5 mb-15 pb-15">
        <v-col cols="12" align="left" xl="8" lg="10" md="12" sm="12" xs="12" class="mt-15">
          <v-row>
            <v-col cols="12" align-self="center" class="pa-0">
              <div>
                <v-img src="/img/aboutred.png"></v-img>
              </div>
            </v-col>
            <v-col cols="12" align-self="center" class="pt-0">
              <p class="caTitle">Why</p>
              <p class="caBody mt-6">
                Nowadays, art, culture and creativity are defined in the sense that they contribute to the generation and production of knowledge. 
                They are products that are created, need a market, and thrive on clients. Besides, culture and creativity have an aesthetic, social, political, economic and idealistic value. 
                Yet, culture, art and creativity waste away if they do not have a market and if they are not received. 
                To make that reception possible it needs entrepreneurship and an effective cultural and creative business. 
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LanguageTest from "@/components/TestAndDevelopment/LanguageTest.vue";
import SelectLocale from "@/components/TestAndDevelopment/SelectLocale.vue";
export default {
  name: "About",
  components: {
    LanguageTest,
    SelectLocale,
  },
  data() {
    return {
      keyBoxes: [
        { title: "Some key words" },
        { title: "To describe the project" },
        { title: "To make it more digestible" },
        { title: "To describe the project" },
        { title: "Some key words" },
      ],
      results: [
        {
          title: "Output 1 - Classroom Activities Guide - How Can Teachers Involve Technology, Games, Art and Nature into mathematics?",
          description: `This Planning Guide for Teachers contains over 60 classroom activities designed to support the teaching of Maths available to download.
This Guide will set out the activities in order to facilitate both teacher and school planning for Math teaching. 
The guide will be divided into chapters, for different age groups. 
Activities included in it are suitable for different class levels from Primary level to secondary l level, learning objectives for each activity, opportunities for applying the skills of: questioning, observing, predicting, investigating and experimenting, estimating and measuring, analyzing, exploring, planning, making and evaluating. 
`,
        },
        {
          title: "Output 2 - M@THGAN Game",
          description: `The created M@THGAN game will assist in developing a positive attitude towards mathematics and learning. 
This output focuses on the development of the game with a printed and online version for students to learn mathematical principles through playing a serious game.
The game will be playable in single mode and in multiplayer mode.
`,
        },
        {
          title: "Output 3 - MOOC M@THGAN",
          description: `6 MOOCs will be created and uploaded to project eLearning platform. 
Those are:
-	  Module 1: Effective Ways of Teaching Mathematics. 
-	  Module 2: Model of Classroom Activities Handbook. 
-	  Module 3: Using Games and Gamification as educational tools. The usage of M@THGAN game. 
-	  Module 4: Computational thinking and using coding in Mathematics teaching. 
-	  Module 5: Open, creative and visual Mathematics. Art In MATH. 
-	  Module 6: Outdoor Mathematics. Nature in Math.
`,
        },
      ],
    };
  },
};
</script>

<style scoped>
/* General Page */
.homePageWidth {
  width: 90vw;
  margin: auto auto;
}

/* About Company */
.aboutTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 48px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.aboutDescription {
  color: #6a6a6a;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 18px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.aboutKeyBoxes {
  margin: 0px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  text-align: center;
  background-color: #e0f1ff;
  padding: 5px 10px 5px 10px;
  font-size: 12px;
}
.aboutImageBox {
  width: 100%;
  height: 100%;
  background-color: #d1d1d1;
  opacity: 1;
}

/* Goal */
.goalTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 34px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.goalDescription {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.goalImage {
  width: 100%;
  height: 100%;
  background-color: #d1d1d1;
  opacity: 1;
}

/* For Whom */
.whomTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 34px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whomDescription {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whomImage {
  width: 100%;
  height: 100%;
  background-color: #d1d1d1;
  opacity: 1;
}
.whomBackground {
  background-color: #f2f2f2;
  opacity: 1;
}

/* Why */
.whyTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 34px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whyDescription {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whyImage {
  width: 100%;
  height: 100%;
  background-color: #d1d1d1;
  opacity: 1;
}
.aboutChip {
  font-size: var(--caption-size) !important;
  font-weight: var(--caption-weight);
  font-family: var(--body-font);
  border-radius: 6px !important;
  background-color: #e1f2ea !important;
  padding: 20px 24px !important;
  pointer-events: none;
}
</style>
