<template>
  <div class="homePageWidth">
    <v-row>
      <v-col cols="12" class="mt-15"></v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12" class="pt-0"></v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="pt-0 pb-15">
        <NewsMainPage></NewsMainPage>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import NewsDialog from "@/components/dialogs/newsDialog.vue";
import NewsMainPage from "@/components/News/NewsMainPage.vue"
export default {
  name: "News",
  components: {
    NewsDialog,
    NewsMainPage
  },
  data() {
    return {
      // Fix
      accessKey: window.btoa("bac436b32a36431bb437b9509b6d3495"),
      // Can check 106/7
      tenant: 113,
      allTheNews: [],
      theNewsContent: [],
      colorArr: ["#205072", "#329D9C", "#D83636", "#DD9A30", "#205072", "#329D9C"],
    };
  },
  mounted() {
    this.getAllNews();
  },
  methods: {
    // Get all news made for this tenant
    getAllNews() {
      this.$http
        .get(`https://app.followup.prios.no/api/resource_management/news?mode=getpublicnews&tenant_id=${this.tenant}`, {
          headers: { Tempaccess: this.accessKey },
        })
        .then((response) => {
          this.allTheNews = response.data;
          console.log("Responsen", response.data);
        });
    },

    // Get all the content of the clicked news
    getNewsContent(data) {
      this.$http
        .get(`https://app.followup.prios.no/api/resource_management/news_content?mode=getpublicnews&news_id=${data.id}`, {
          headers: { Tempaccess: this.accessKey },
        })
        .then((response) => {
          this.theNewsContent = response.data;
          console.log("Responsen", response.data);
        })
        .then((responsen) => {
          this.openTheDialog(data, this.theNewsContent);
        });
    },

    // Open the Dialog while passing the correct data
    openTheDialog(originalData, contentData) {
      this.$refs.openingNewsDialog.openNewsDialog(originalData, contentData);
    },
  },
};
</script>

<style scoped>
/* General Page */
/* .homePageWidth {
  width: 90vw; 
  margin:auto auto;
} */
</style>
