<template>
  <div>{{ $t('message.hello') }}</div>
</template>

<script>
export default {
  name: 'Languagetest',
  data(){
    return {

    }
  }
}
</script>