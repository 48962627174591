<template>
  <div :class="$vuetify.breakpoint.lgAndDown ? 'pr-3 pl-3' : ''">
    <PartnerDialog ref="openPartnerDialog"></PartnerDialog>
    <v-col cols="12" align="center">
      <v-col cols="12" align="left" xl="8" lg="10" md="12" sm="12" xs="12" :class="$vuetify.breakpoint.smAndDown ? 'mt-0' : 'mt-15'">
        <v-row>
          <v-col cols="12" xl="10" lg="10" md="10" sm="12" xs="12" class="mt-12">
            <!-- <v-card class="ma-0 pa-0 box" flat> -->
            <p class="ma-0 pa-0 caHeadline">Partners</p>
            <!-- </v-card> -->
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12" v-for="(item, index) in projectPartners" :key="index">
            <v-card class="cardBorder ma-2 pa-2 px-2" height="100%">
              <v-row>
                <v-col cols="12">
                  <v-card height="90px" flat class="d-flex" :href="item.link" target="_blank" v-if="item.title == 'PRIOS KOMPETANSE'">
                    <v-img class="mt-5" :src="item.image" contain />
                  </v-card>
                  <v-card height="120px" flat class="d-flex" :href="item.link" target="_blank" v-else>
                    <v-img class="mt-5" :src="item.image" contain />
                  </v-card>
                </v-col>
                <v-col class="pb-0 mb-0" cols="12">
                  <p class="pl-3 partnerTitle text-center mb-0" style="min-height:70px;">
                    {{ item.title }}
                    <span>
                      <v-btn icon :href="item.link" target="_blank">
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-btn>
                    </span>
                  </p>
                </v-col>
                <v-col v-if="selectedIndex !== index" cols="12" class="ma-0 pa-0 pl-3 pr-3">
                  <!-- <p class="pl-3 pr-3 partnerDescriptionPre">{{ item.description }}</p> -->
                </v-col>
                <v-expand-transition>
                  <div v-show="index === selectedIndex">
                    <v-divider></v-divider>
                    <v-card-text>
                      <!-- <p class="partnerDescriptionPost mt-0 mb-0">{{ item.description }}</p> -->
                      <p class="partnerDescriptionPost mt-0 mb-0" v-html="item.description"></p>
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-row>
              <!-- <p class="text-center mb-0 mt-0 pt-0" v-if="selectedIndex !== index">read more</p> -->
              <v-card-actions class="pt-0 pb-0">
                <v-spacer></v-spacer>
                <!-- <v-btn icon v-if="selectedIndex !== index" @click="openingPartnerExpand(index)">
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn> -->
                <v-btn block color="#BBDEFB" @click="$refs.openPartnerDialog.openDialog(item)" class="mt-5">Read More</v-btn>
                <!-- <v-btn icon v-else @click="closingPartnerExpand()">
                  <v-icon large color="black">mdi-chevron-up</v-icon>
                </v-btn> -->
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-col>
  </div>
</template>

<script>
import PartnerDialog from "@/components/dialogs/partnerDialog.vue"
export default {
  components: {
    PartnerDialog
  },
  data() {
    return {
      selectedIndex: null,
      projectPartners: [
        {
          title: "VILNIUS JUSTIN VIENOŽINSKIS SCHOOL OF ART",
          image: "img/VJVDM_logo.jpg",
          link: "https://vienozinskis.lt/",
          description: ` Vilnius Justinas Vienožinskis Art School was established in 1958.
  <br>
  The founder of the school is Vilnius city municipality. In 1994 the school was given the name of painter Justinas Vienožinskis.
  <br>
  The main mission of the non-formal education in J. Vienožinskis Art School is to develop artistic capabilities and creativeness of both children and adults through art studies.
  <br>
  The school provides the training of versatile artistic capabilities, covering the expression of intuition, feelings and intellect, as well as combining an emotional experience and critical thinking, disclosing the individuality and independence of personality.
  <br><br>
  Since the establishment of the school, traditional art disciplines – composition, painting, drawing, graphics, sculpture, ceramics, history of fine arts – are valued and nourished.
  <br>
  However, interdisciplinary spatial and time-based arts deserve their focus as well.
  <br><br>
  More than 50 teachers – most of them are acknowledged artists or art critics – and more than 1000 pupils of the age groups of 12-15 and 16-18 years comprise school‘s community.
          `,
        },
        {
          title: "SOCIAL LEADERSHIP ASSOCIATION",
          image: "img/SLA_logo_CMYK-EN.png",
          link: "https://socialinelyderyste.lt/en/pagrindinis-english/",
          description: '',
        },
        {
          title: "LIEPAJA MUSIC, ART AND DESIGN SECONDARY SCHOOL",
          image: "img/lmdv.png",
          link: "https://lmmdv.edu.lv/about",
          description: `Liepaja Secondary Art School was founded in 1926 based on applied arts, with 4 specializations: Color, Fiber, Clay and Wood. Since then school has changed its name several times and developed other study programs. 
  Since 2016 it is called The Centre of Competency Based Professional Education Liepaja Music, Art and Design Secondary School by combining music and art schools of Liepaja.
  Its main aim is to create an educational environment, to organize and implement an educational process that ensures the achievement of the vocational education goals set in the state vocational secondary education standards in music and art profession standards.
  <br><br>
  One of the school aims is to promote knowledge, skills and attitudes, which provides acquisition of vocational education and preparing professionals in music and art industry with theoretical knowledge and practical skills ready to get involved in labor market. 
  There are about 300 learners and 160 educators in the secondary school.
  <br><br>
  School implements vocational secondary education programs, which provide the opportunity to acquire the third level of professional qualification and additionally perform the functions of assessment of the regional methodological center, 
  the continuing education center of educators and the professional competence acquired outside the formal education system.
  <br><br>
  School mission: To promote the creativity in the context of Latvia and the world, to ensure quality and competitive education in Music, Art and Design which helps to educate excellent artists both in Art and Music.
  <br><br>
  School vision: A competitive educational centre both in national and international level what makes competitive and excellent artists with the help of highly qualified teachers, innovative technologies in the cooperation with students, 
  their parents high school teachers employers, self government and state institutions. 
  School like a regional culture and methodical centre contributes to the quality of culture education, art and creative industries and to the cultural preservation and accessibility in public interests. 
  <br><br>
  At this moment, school offers four-year secondary vocational education (after the 9th grade in elementary school) and two-year upper-secondary vocational education (after the 12th grade in secondary school) in one of the following specialties in art and design field: 
  Fashion design, Leather design, Wood design, Metal (jewelry) design, Textile design, Interior design, Advertisement design, Multimedia design, Photo design, Architecture, Stylist, Arts. And nine education programs in music field.
  <br><br>
  Art and design students learn a variety of subjects in professional and secondary education:
    1. General syllabus – Mathematics, Latvian language and literature, Foreign languages - English, French, German, Psychology, Informatics, Technical drawing, History, History of culture, History of art, Design basics, Business basics, Sport.
          `,
        },
        {
          title: "VENTSPILS ART SCHOOL",
          image: "img/MS_logo.jpg",
          link: "http://www.ventspilsmakslasskola.lv/",
          description: `Ventspils Art School is an educational institution founded by the Ventspils City Council, which implements education in two professional orientation programs and an interest education program in preparatory classes.
          <br><br>
  Ventspils Art School was founded on May 8, 1992, the first students were admitted to the school on September 1, 1993.<br>
  On June 13, 2002, the school&#39;s vocational education program &quot;Visual plastic arts&quot; was licensed with the code 20V 211 00 1, and the school was accredited for the first time on April 14, 2003.
  <br><br>
  Starting with the 2013/2014 academic year Ventspils Art School started to implement the vocational education program &quot;Visual plastic arts&quot; with the code 30V 211 00 1, but in 2014 the program was accredited.
  <br>
  School studies take place after classes in a comprehensive school and there are no tuition fees for students. Professional education can be learned at school from the age of 8 to 15, and vocational education from the age of 5.
  <br><br>
  Art school students and teachers regularly participate in competitions and seminars organized by the Latvian National Cultural Center, festivals, foreign competitions, plein airs, as well as various events and exhibitions in the city of Ventspils.
  <br>
  In our school we offer to acquire drawing, painting, ceramics, graphics, composition, computer graphics, work in material, language of arts and design basics.
          `,
        },
        {
          title: "PRIOS KOMPETANSE",
          image: "img/prios.webp",
          link: "https://www.prios.no/en-gb",
          description: `Prios Kompetanse AS (Prios) from Steinkjer, Norway is a research-based corporation which aims to implement the idea of lifelong learning, support and conduct development projects. 
  <br>
  The center is open to all who wish to develop their talents, refresh their knowledge, improve their business, needs new or better digital tools or have ideas in need for support to be fulfilled. 
  <br>
  The main sectors and activities is: - Educational activities focused on adult learners and labor market service within basic skills training, VET, entrepreneurship training and tailored training for businesses.
  <br>
  <span class="ml-4">
    - Software support for their educational activities as well as developing new digital solutions based upon requests and needs in different projects, we participate in. For instance, Prios have developed and own the Follow-Up® concept.  
  </span>
  <br>
  Consulting activities mostly supports business clients within internal innovation processes, HR processes, economical advises and management improvements.
          `,
        },
      ],
    };
  },
  methods: {
    openingPartnerExpand(index) {
      this.selectedIndex = index;
    },

    closingPartnerExpand() {
      this.selectedIndex = null;
    },
  },
};
</script>

<style scoped>
/* Card CSS */
/* .box {
  border-bottom: 3px solid transparent;
  border-image: linear-gradient(to right, #dd9a30 5%, transparent 5%);
  border-image-slice: 1;
} */
.cardBorder {
  border: 3px var(--brand-color-main) solid;
  border-radius: 7px;
  transition: 0.2s ease;
}
.cardBorder:hover {
  border: 3px var(--brand-color-secondary) solid;
}

/* Text styling */
.partnerChapterTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 46px;
  color: #434343;
}
.partnerTitle {
  font-family: var(--subtitle-font);
  font-weight: var(--subtitle-weight);
  font-size: 20px;
  color: var(--subtitle-color);
}
.partnerDescriptionPre {
  font-family: var(--body-font);
  font-weight: var(--body-weight);
  color: var(--body-color);
  font-size: var(--body-size);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.partnerDescriptionPost {
  font-family: var(--body-font);
  font-weight: var(--body-weight);
  font-size: var(--body-size);
  color: var(--body-color);
  line-height: var(--body-height);
}
</style>
