<template>
  <div>
    <v-row class="mt-10 mx-auto">
      <!-- Cards -->
      <v-col cols="12" class="mt-10"></v-col>
      <v-col cols="12">
        <p class="text-center title">Outputs</p>
      </v-col>

      <!-- paragraph style wrapped -->
      <v-col cols="12" style="max-width: 80%" class="mx-auto">
        <p style="font-size: 18px">
          Welcome to a journey into the Cultural Entrepreneurship for a Greener
          Future (CA4GF) initiative. Rooted in the Baltic-Nordic region, this
          platform unveils outcomes aimed at empowering art school students with
          entrepreneurial skills. The mission? To seamlessly blend cultural
          creativity with sustainability, nurturing a generation of artists
          poised to drive positive change.
        </p>
        <p style="font-size: 18px">
          CA4GF thrives on partnerships, resulting in seminars and workshops
          across Lithuania, Latvia, and Norway that foster knowledge exchange
          among project partners.
        </p>
        <p style="font-size: 18px">
          This journey has led to notable achievements, including a pioneering
          curriculum for cultural entrepreneurship across Latvia, Lithuania, and
          Norway. This curriculum integrates ecological awareness into artistic
          studies, disseminates insights to Nordic region countries, and upholds
          a commitment to excellence and result integrity.
        </p>
        <p style="font-size: 18px">
          At its core lies a curated curriculum, fusing ecological insights and
          entrepreneurial strategies. This comprehensive approach embodies the
          CA4GF project's dedication to cultivating innovative,
          environmentally-conscious artists shaping a sustainable future.
        </p>
      </v-col>

      <!-- 1. Curriculum: Ecological Entrepreneurship and Design -->
      <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" class="pb-15 pt-10">
        <a
          href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/c58152e9-40f4-4dcb-996d-b694549bc5f8.pdf"
          style="text-decoration: none"
          target="_blank"
        >
          <v-hover v-slot:default="{ hover }">
            <v-card
              :elevation="hover ? 12 : 2"
              title="The creative process, is creativity (and innovation) something special??"
              class="ma-10"
            >
              <v-img
                src="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/096b3614-08ac-4331-88a0-185bad9eb0f8.png"
                alt="Concept Assessment Image"
                width="auto"
                height="500px"
                contain
              >
              </v-img>
            </v-card>
          </v-hover>
        </a>
      </v-col>
      <!-- 2. Methodology for Implementing Green Education in the Classroom -->
      <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" class="pt-10">
        <a
          href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/d9df1629-3d60-4305-9503-526130b1e50e.pdf"
          style="text-decoration: none"
          target="_blank"
        >
          <v-hover v-slot:default="{ hover }">
            <v-card
              :elevation="hover ? 12 : 2"
              title="Concept Assessment - from two Danish Academies of Technologies and Business"
              class="ma-10"
            >
              <v-img
                src="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/096b3614-08ac-4331-88a0-185bad9eb0f8.png"
                alt="Concept Assessment Image"
                width="auto"
                height="500px"
                contain
              >
              </v-img>
            </v-card>
          </v-hover>
        </a>
      </v-col>
      <!-- 3. Methodology for Design Learning Programs -->
      <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" class="pt-10">
        <a
          href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/28f1209e-3969-4cde-8ed2-0ca76fe44e03.pdf"
          style="text-decoration: none"
          target="_blank"
        >
          <v-hover v-slot:default="{ hover }">
            <v-card
              :elevation="hover ? 12 : 2"
              title="Didactics for Entrepreneurial Learning ONLINE"
              class="ma-10"
            >
              <v-img
                src="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/096b3614-08ac-4331-88a0-185bad9eb0f8.png"
                alt="Concept Assessment Image"
                width="auto"
                height="500px"
                contain
              >
              </v-img>
              <!-- https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/d4e70b32-dece-45b7-b896-c3c51c021ad6.pdf -->
            </v-card>
          </v-hover>
        </a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accessKey: window.btoa("bac436b32a36431bb437b9509b6d3495"),
      colorArr: [
        "#205072",
        "#329D9C",
        "#D83636",
        "#DD9A30",
        "#205072",
        "#329D9C",
      ],
      resourceFolders: [
        { title: "Video", colour: "", image: "", description: "", template: 1 },
        { title: "Files", colour: "", image: "", description: "", template: 2 },
        // { title: this.$t('resources.courses'), colour: "", image: "", description: "", template: 3 },
        // { title: this.$i18n.t('resources.podcast'), colour: "", image: "", description: "", template: 4 },
        // { title: "Other", colour: "", image: "", description: "", template: 5 },
        {
          title: "External Links",
          colour: "",
          image: "",
          description: "",
          template: 6,
        },
      ],
      resourceTemplate: 0,
      resourceContent: [],
    };
  },
  methods: {
    // Close the Bottom Box
    closeTheResourceBox() {
      this.resourceTemplate = 0;
      this.resourceContent = [];
    },

    // Display the specific Resource Content
    displayResource(templateID) {
      this.resourceTemplate = templateID;
      this.getAllResources();
    },
    getAllResources() {
      this.$http
        .get(
          `https://app.followup.prios.no/api/resource_management/content?tenant_id=000`,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then((response) => {
          this.resourceContent = response.data;
        });
    },
  },
};
</script>

<style scoped>
.pageCardDividerBlue {
  background-color: #205072;
  padding: 2px;
}
.newsCardDividerPositioning {
  margin-left: 1%;
  margin-bottom: 2%;
}
.pageCardArrow {
  color: #205072;
  opacity: 1;
}

.resourceCardTitle {
  font-size: 20px;
  font-weight: bold;
}

.resourceDescription {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
}

.resourcesLanguage {
  font-size: 12px;
}

.rssCardButton {
  font-family: "Lato", sans-serif;
  font-weight: regular;
  font-size: 18px;
  text-align: left;
  color: #205072;
  opacity: 1;
  letter-spacing: 0px;
  text-decoration: underline;
}

.rssCardButtonArrow {
  color: #205072;
  opacity: 1;
}
</style>
