<template>
  <div>
    <NewsDialog ref="openingNewsDialog"></NewsDialog>
    <RssDialog ref="openingRssDialog"></RssDialog>

    <!-- Snackbar for Contact Form -->
    <v-snackbar v-model="contactToast" dark color="success">
      <span>The message has been sent!</span>
      <v-btn class="float-right" x-small dark icon @click="contactToast = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <!-- Home Page Content -->
    <v-row style="width: calc(100% - 2px); margin: 0 11px 0 1px">
      <!-- <v-col cols="12" :style="$vuetify.breakpoint.mdAndDown ? 'margin-top: 24px' : 'margin-top: 62px'"></v-col> -->
      <v-col cols="12" v-if="$vuetify.breakpoint.xlOnly" style="margin-top: 62px"></v-col>
      <v-col cols="12" v-else-if="$vuetify.breakpoint.lgOnly" style="margin-top: 62px"></v-col>
      <v-col cols="12" v-else-if="$vuetify.breakpoint.mdOnly" style="margin-top: 24px"></v-col>
      <v-col cols="12" v-else-if="$vuetify.breakpoint.smOnly" style="margin-top: 32px"></v-col>
      <v-col cols="12" v-else-if="$vuetify.breakpoint.xsOnly" style="margin-top: 32px"></v-col>
      <!-- Intro Image -->
      <v-col cols="12" class="pb-0">
        <v-card elevation="0" style="border-radius: 7px">
          <v-img src="/img/hero.jpg" height="650">
            <p v-if="$vuetify.breakpoint.mdAndDown" class="caHeadline introTitlePhone">Welcome to the project webpage for CA4GF</p>
            <p v-else class="caHeadline" style="margin-left: 17%; margin-top: 10%; color: white; text-shadow: 1px 1px 4px #000000">
              Welcome to the project webpage for CA4GF
            </p>
            <p v-if="$vuetify.breakpoint.mdAndDown" class="introDescriptionPhone">Cultural Entrepreneurship for a Greener Future</p>
            <p v-else class="caBody introDescription">Cultural Entrepreneurship for a Greener Future</p>
            <!-- M@THGAN project focuses on improving effective teaching ways of MATHEMATICS through TECHNOLOGY, GAME, ART and NATURE -->
          </v-img>
        </v-card>
      </v-col>

      <!-- 3 Cards in center of cards -->
      <!-- <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12" class="pt-0"></v-col> -->
      <v-col cols="12" align="center">
        <v-col cols="12" xl="8" lg="12" md="12" sm="12" xs="12" class="pt-0">
          <v-row>
            <v-col cols="12" align="left" xl="4" lg="4" md="6" sm="12" xs="12" v-for="(pageCard, pageCardIndex) in pageCards" :key="pageCardIndex">
              <v-card class="pageCard pa-4" :to="pageCard.link" height="100%" style="position: relative">
                <!-- <v-divider v-if="pageCardIndex == 0" class="pageCardDividerBlue"></v-divider>
              <v-divider v-if="pageCardIndex == 1" class="pageCardDividerGreen"></v-divider>
              <v-divider v-if="pageCardIndex == 2" class="pageCardDividerRed"></v-divider> -->
                <v-row justify="space-between">
                  <v-col cols="8">
                    <p class="mt-2 pageCardTitle" style="white-space: nowrap">{{ pageCard.name }}</p>
                  </v-col>
                  <v-col cols="4" class="flex-shrink-1" align="right">
                    <button v-if="pageCardIndex == 0" class="iconbutton" style="background-image: url('/img/icon button bg green.svg')">
                      <span class="material-icons-round iconbuttontext">east</span>
                    </button>
                    <button v-if="pageCardIndex == 1" class="iconbutton" style="background-image: url('/img/icon button bg yellow.svg')">
                      <span class="material-icons-round iconbuttontext">east</span>
                    </button>
                    <button v-if="pageCardIndex == 2" class="iconbutton" style="background-image: url('/img/icon button bg blue.svg')">
                      <span class="material-icons-round iconbuttontext">east</span>
                    </button>
                  </v-col>
                </v-row>
                <p class="pageCardDescription">{{ pageCard.description }}</p>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-col>

      <!-- Info Text -->
      <v-col cols="12" align="center">
        <v-col cols="12" align="center" xl="3" lg="5" md="6" sm="8" xs="12">
          <p class="caTitle"> What is CA4GF? </p>
          <p class="caBody">
            Cultural Entrepreneurship for a Greener Future was developed by art schools and experts from Latvia, Lithuania and Norway, 
            and funded by the Nordic and Baltic cooperation, via NordPlus, to develop a Entrepreneurship curriculum where sustainable creativty is viewed as the link to entrepreneurship in art and culture. 
          </p>
        </v-col>
      </v-col>

      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12" class="pt-0"></v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="pt-0">
        <NewsMainPage></NewsMainPage>
      </v-col>
      <!-- See all news button - go to page -->
      <v-col cols="12" align="center" class="mt-2">
        <v-btn class="seeAllButtonBorder seeAllButtonText" rounded to="/news">
          See all news
        </v-btn>
      </v-col>


      <!-- To keep the structure -->
      <v-col cols="12"></v-col>

      <!-- RSS -->
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12" class="pt-0"></v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="pt-0">
        <RssForHome></RssForHome>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import NewsDialog from "@/components/dialogs/newsDialog.vue";
import RssDialog from "@/components/dialogs/rssFeedDialog.vue";
import RssTest from "@/components/rss/rssTest.vue";
import RssForHome from "@/components/rss/rssForHome.vue"
import NewsMainPage from "@/components/News/NewsMainPage.vue"
import VisitorTracker from "@/components/VisitTracker/VisitorTracker.vue"
export default {
  name: "Home",
  components: {
    NewsDialog,
    RssDialog,
    RssTest,
    RssForHome,
    NewsMainPage,
    VisitorTracker
  },
  data() {
    return {
      accessKey: window.btoa("bac436b32a36431bb437b9509b6d3495"),
      tenant: 113,
      allTheNews: [],
      theNewsContent: [],
      colorArr: ["#205072", "#329D9C", "#D83636", "#DD9A30"],
      contactToast: false,
      // Contact Form
      contactForm: {
        name: "",
        email: "",
        message: "",
        subject: "",
        sentFrom: "Mathgan",
      },
      // Rules for the contact Form
      rules: {
        requiredField: (value) => !!value || "This field is required",
        requiredName: (value) => !!value || "Your name is required",
        requiredEmail: (value) => !!value || "A propper email adress is required",
        emailRequirement: (value) => /.+@.+\..+/.test(value) || "A proper email address is required",
      },
      contactToast: false,
      isContactFormValid: true,
      // In Page Pages Cards
      pageCards: [
        { name: "About the project", color: "", link: "/about", description: "Read about the project, aims and the results it will produce" },
        { name: "Available resources", color: "", link: "/resources", description: "Here you will find different resources made in the project" },
        { name: "Partners", color: "", link: "/partners", description: "Find the partners and learn more about them" },
      ],
      // Dummy data below - Replace when db are implemented
      fakeNewsCards: [
        {
          title: "News title",
          article: "Lorem ipsum dolor sit amet, nihil repellat arcitecto asperiores sequi facere",
          external_link: "",
          image: "",
        },
        {
          title: "News title",
          article: "Lorem ipsum dolor sit amet, nihil repellat arcitecto asperiores sequi facere",
          external_link: "",
          image: "",
        },
        {
          title: "News title",
          article: "Lorem ipsum dolor sit amet, nihil repellat arcitecto asperiores sequi facere",
          external_link: "",
          image: "",
        },
      ],
      fakeRSSfeed: [
        {
          title: "RSS feed title",
          image: "",
          content: "Lorem ipsum dolor sit amet, eligendi distinctio iste Iste quis rerum",
          date: "02.03.2022",
        },
        {
          title: "RSS feed title",
          image: "",
          content: "Lorem ipsum dolor sit amet, eligendi distinctio iste Iste quis rerum",
          date: "02.03.2022",
        },
        {
          title: "RSS feed title",
          image: "",
          content: "Lorem ipsum dolor sit amet, eligendi distinctio iste Iste quis rerum",
          date: "02.03.2022",
        },
        {
          title: "RSS feed title",
          image: "",
          content: "Lorem ipsum dolor sit amet, eligendi distinctio iste Iste quis rerum",
          date: "02.03.2022",
        },
      ],
    };
  },
  mounted() {
    this.getAllNews();
  },
  methods: {
    // Form: Clear all data
    clearForm() {
      this.$refs.form.reset();
    },
    // Form: Send Data
    postContactForm() {
      const ContactForm = {
        name: this.contactForm.name,
        email: this.contactForm.email,
        subject: this.contactForm.subject,
        message: this.contactForm.message,
        sentFrom: this.contactForm.sentFrom,
      };
      console.log("Sending Contact Form", ContactForm);
      this.messageSent();
      this.clearForm();
    },
    // Form: Notification that message have been sent, through Toast.
    messageSent() {
      this.contactToast = true;
    },

    // Get all news made for this tenant
    getAllNews() {
      this.$http
        .get(`https://app.followup.prios.no/api/resource_management/news?mode=getpublicnews&tenant_id=${this.tenant}`, {
          headers: { Tempaccess: this.accessKey },
        })
        .then((response) => {
          this.allTheNews = response.data;
          console.log("Responsen", response.data);
        });
    },

    // Get all the content of the clicked news
    getNewsContent(data) {
      this.$http
        .get(`https://app.followup.prios.no/api/resource_management/news_content?mode=getpublicnews&news_id=${data.id}`, {
          headers: { Tempaccess: this.accessKey },
        })
        .then((response) => {
          this.theNewsContent = response.data;
          console.log("Responsen", response.data);
        })
        .then((responsen) => {
          this.openTheDialog(data, this.theNewsContent);
        });
    },

    // Open the Dialog while passing the correct data
    openTheDialog(originalData, contentData) {
      this.$refs.openingNewsDialog.openNewsDialog(originalData, contentData);
    },
  },
};
</script>

<style scoped>
/* General Page */
/* .homePageWidth {
  width: 90vw;
  margin: auto auto;
} */

/* Intro Classes for box and text at the top */
/* .introImage {
  height:650px;
  background-color: rgb(213, 211, 211);
} */
.introTitlePhone {
  font-size: 36px;
  color: white;
  opacity: 1;
  margin-left: 5%;
  margin-top: 30%;
  text-shadow: 1px 1px 2px #000000;
}
.introDescription {
  font-size: 28px;
  font-weight: bold;
  color: white;
  margin-left: 17%;
  margin-right: 15%;
  text-shadow: 1px 1px 2px #000000;
}

.introDescriptionPhone {
  font-size: 22px;
  color: white;
  font-family: var(--body-font);
  font-weight: bold;
  margin-left: 5%;
  margin-right: 5%;
  text-shadow: 1px 1px 4px #000000;
}
</style>
