<template>
  <div class="pageWidth">
    <v-row>
      <v-col cols="12" class="mt-15"></v-col>
      <v-col cols="12" class="mt-15">
        <p class="text-center barlowFont primaryTextColor smabHeadliners">Training modules</p>
        <p class="text-center latoFont primaryTextColor smabDescriptionText pt-10">This page will be designed when content are decided</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return{

    }
  }
}
</script>

<style scoped>
  @import '../../assets/styles/styleSMAB.css';
</style>