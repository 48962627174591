<template>
  <div class="pageWidth">
    <v-row>
      <v-col cols="12" class="mt-5"></v-col>
      <v-col cols="12">
        <v-img src="../../assets/images/aboutPageImage.png" height="600">
          <v-row>
            <v-col cols="4"></v-col>
            <v-col cols="6" class="pt-15">
              <!-- About us title -->
              <p class="text-center smabHeadliners bolderText primaryTextColor">About us</p>
              <!-- About us description -->
              <p class="text-center smabDescriptionText primaryTextColor">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                Sed ut perspiciatis unde omnis iste natus error sit, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. 
                Nemo enim ipsamt aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. 
                Neque porro quisquam est, qui dolorem ipsu, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam .
              </p>
              <!-- About Goals Title -->
              <p class="text-center smabHeadliners bolderText primaryTextColor">Our goals</p>
              <!-- About Goals Description -->
              <p class="text-center smabDescriptionText primaryTextColor">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore nim id est laborum.Sedluptatem accusantium doloremque laudantium,totam rem aperiam, 
                eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatemlorem ipsum.
              </p>
            </v-col>
          </v-row>
        </v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return{

    }
  }
}
</script>

<style scoped>
  @import '../../assets/styles/styleSMAB.css';
</style>